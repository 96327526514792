
import React, { useContext, useEffect } from 'react'
import firebase from 'gatsby-plugin-firebase'
import * as Styles from '../styles'
import InsidePageLayout from '../../../layouts/insidePageLayout'
import EmailComponent, { IEmail } from '../../Email'
import BackIcon from '../../../images/svg/back.svg'
import SentMail from '../../../images/svg/sent_mail.svg'
import { siteData } from '../../../common/siteData'
import { AppStateEvent, Locations, MixpanelContext } from '../../../common/tracking'

type FormStages = 'idle' | 'inprocess' | 'waiting-verification' |'redo' | 'signin-error'

const actionCodeSettings = {
	// URL you want to redirect back to. The domain (www.example.com) for this
	// URL must be in the authorized domains list in the Firebase Console.
	url: `${process.env.GATSBY_ROOT_URL}${siteData.finishPath}`,
	// This must be true.
	handleCodeInApp: true,
	// iOS: {
	// bundleId: 'com.example.ios',
	// },
	// android: {
	// packageName: 'com.example.android',
	// installApp: true,
	// minimumVersion: '12',
	// },
	dynamicLinkDomain: 'auth.stickies.fun',
}

const SignIn:React.FC = () => {
	const [stage, setStage] = React.useState<FormStages>('idle')
	const [email, setEmail] = React.useState<string>('')
	const [signInError, setSignInError] = React.useState<string>('')
	const mixpanel = useContext(MixpanelContext)

	useEffect(() => {
		mixpanel.track(AppStateEvent.logInPageVisited)
	}, [mixpanel])

	const handleSubmit = (theEmail:string):void => {
		setStage('inprocess')
		firebase.auth().sendSignInLinkToEmail(theEmail, {
			...actionCodeSettings,
			url: `${actionCodeSettings.url}`,
		})
			.then(() => {
				window.localStorage.setItem('emailForSignIn', theEmail)

				mixpanel.track(AppStateEvent.authEmailSubmitted, {
					location: Locations.signInPage,
				})
			})
			.catch((error) => {
				setStage('signin-error')
				setSignInError(error.message)
			})
			.finally(() => {
				setStage('waiting-verification')
			})
	}

	const signUpForm = (
		<Styles.SignUpForm>

			{
				(stage === 'idle' || stage === 'redo') && (
					<>
						<EmailComponent
							defaultValue=""
							onSubmit={(data:IEmail) => {
								setEmail(data.email)
								handleSubmit(data.email)
							}}
							buttonText="Go"
						/>
						<Styles.Navigation>
							Need an account?&nbsp;
							<Styles.StyledLink2 to="/account/signup">Sign Up</Styles.StyledLink2>
						</Styles.Navigation>
					</>
				)
			}

			{
				(stage === 'waiting-verification' || stage === 'inprocess') && (
					<>
						<SentMail />
						<Styles.InfoMessage>
							{siteData.linkSentSignInMessage}
							&nbsp;
							<Styles.Email>{email}</Styles.Email>
						</Styles.InfoMessage>
					</>
				)
			}

		</Styles.SignUpForm>
	)

	const topNav = (
		<Styles.TopNav>
			{
				(stage === 'waiting-verification' || stage === 'inprocess') && (
					<Styles.BackButton onClick={() => setStage('redo')}><BackIcon /></Styles.BackButton>
				)
			}

		</Styles.TopNav>
	)

	const verificationErrorUI = (
		<Styles.Title>
			Hmmmm...
			{' '}
			{signInError}
		</Styles.Title>
	)

	return (
		<InsidePageLayout>
			<Styles.InnerBody>
				{
					stage === 'signin-error' && (
						verificationErrorUI
					)

				}
				{/* {
					stage !== 'signin-error' && loadingAuthState && (
						<Styles.Title>
							Loading...
						</Styles.Title>
					)
				} */}
				{/* {
					stage !== 'signin-error' && !loadingAuthState && user?.emailVerified && (
						<>
							<Styles.AlreadyAuthedContainer>
								{ user && user.emailVerified && (
									<>
										<div>
											{user.displayName || user.email}
										</div>
										<Styles.StyledLink to="/me/notifications">
											Manage Notifications
										</Styles.StyledLink>
									</>
								)}
							</Styles.AlreadyAuthedContainer>
							<Styles.LogOutButton onClick={() => (Logout as Function)()}>LOGOUT</Styles.LogOutButton>
						</>
					)
				} */}

				{
					// stage !== 'signin-error' && !loadingAuthState && !user?.emailVerified && (
					stage !== 'signin-error' && (
						<Styles.Modal>
							{topNav}
							<Styles.Title>
								Sign In
							</Styles.Title>
							{/* {(stage === 'idle' || stage === 'redo') && (<Styles.SubTitle>Enter your email.</Styles.SubTitle>)} */}
							{signUpForm}

						</Styles.Modal>
					)
				}

			</Styles.InnerBody>

		</InsidePageLayout>

	)
}

export default SignIn
