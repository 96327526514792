import React from 'react'
import { Router } from '@reach/router'
import SignIn from '../../components/User/SignIn'
import SignUp from '../../components/User/SignUp'
// import Profile from '../../components/Auth/Profile'
import Finish from '../../components/User/Finish'
import FinishBid from '../../components/User/FinishBid'
import NonAuthenticatedRoute from '../../components/User/NonAuthenticatedRoute'
// import PrivateRoute from '../../components/Auth/PrivateRoute'
import { AuthProvider } from '../../components/User/AuthProvider'

const App = () :JSX.Element => (
	<AuthProvider enableAnonymouseAccountCreation={false}>
		<Router basepath="/account">
			<NonAuthenticatedRoute path="/signin" component={SignIn} />
			<NonAuthenticatedRoute path="/signup" component={SignUp} />
			<Finish path="/finish" />
			<FinishBid path="/finishbid" />
			{/* <PrivateRoute path="/profile" component={Profile} /> */}
			{/* <SignIn path="/signin" />
			<SignUp path="/signup" /> */}
		</Router>
	</AuthProvider>
)

export default App
