import React, { useContext } from 'react'
import { navigate } from 'gatsby'
import { AuthContext, AuthContextProps } from './AuthProvider'

const NonAuthenticatedRoute = ({ component: Component, ...rest }:{component:any; location:any}):JSX.Element => {
	const { user, loadingAuthState }:Partial<AuthContextProps> = useContext(AuthContext)

	// console.log(`non auth ${location.pathname}`)

	if (loadingAuthState) {
		// console.log('loading auth state')
		return <></>
	}

	if (!loadingAuthState && user && !user.isAnonymous) {
		// console.log('user is logged in and not anonymous, so should redirect to me/notifs')
		// If we’re logged in, redirect to the profile page.
		navigate('/me/notifications', { replace: true })
		return <></>
	}

	return <Component {...rest} />
}

export default NonAuthenticatedRoute
