
import React, { useEffect, useContext } from 'react'
import firebase from 'gatsby-plugin-firebase'
import * as Styles from '../styles'
import InsidePageLayout from '../../../layouts/insidePageLayout'
import withLocation from '../../withLocation'
import EmailComponent, { IEmail } from '../../Email'
import DiscoverButton from '../../DiscoverButton'
import { MixpanelContext, AppStateEvent } from '../../../common/tracking'

interface ComponentProps {
  search: any
}

type FormStages = 'verifying' | 'email-missing' | 'verified' | 'verification-error'

interface IUserInfo {
	isNew: boolean | null | undefined
	email: string | null | undefined
}

const FinishSignUp = (props:ComponentProps):JSX.Element => {
	const { search } = props
	const { customid: customId } = search
	const [stage, setStage] = React.useState<FormStages>('verifying')
	const [verificationError, setVerificationError] = React.useState<string>('')
	const [email, setEmail] = React.useState<string | null>(() => {
		if (typeof window !== 'undefined') {
			return window.localStorage.getItem('emailForSignIn')
		}
		return null
	})

	const [userInfo, setUserInfo] = React.useState<IUserInfo | null>(null)
	const mixpanel = useContext(MixpanelContext)

	useEffect(() => {
		mixpanel.track(AppStateEvent.finishPageVisisted)
	}, [mixpanel])

	const mergeRecords = (inputs:{customId:string}):Promise<any> => {
		// console.log('saving...', inputs)
		const customData = firebase.functions().httpsCallable('pullCustomizedToNewUser')
		return customData(inputs)
	}

	useEffect(() => {
		// if (loadingAuthState) return
		if (stage === 'verification-error') return
		if (stage === 'verified') return
		if (stage === 'email-missing') return

		// console.log('init effects')
		// Confirm the link is a sign-in with email link.
		if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
			// Additional state parameters can also be passed via URL.
			// This can be used to continue the user's intended action before triggering
			// the sign-in operation.
			// Get the email if available. This should be available if the user completes
			// the flow on the same device where they started it.
			// let email = window.localStorage.getItem('emailForSignIn')
			if (!email) {
				setStage('email-missing')
				// User opened the link on a different device. To prevent session fixation
				// attacks, ask the user to provide the associated email again. For example:
				// email = window.prompt('Please provide your email for confirmation')
				// email = ''
			} else if (email) {
			// The client SDK will parse the code from the link for you.
				// console.log(`email:[${email}]`)
				firebase.auth().signInWithEmailLink(email, window.location.href)
					.then(async (result:firebase.auth.UserCredential) => {
						// console.log(`${JSON.stringify(result.additionalUserInfo)}`)
						// Clear email from storage.
						window.localStorage.removeItem('emailForSignIn')
						// You can access the new user via result.user
						// Additional user info profile not available via:
						// result.additionalUserInfo.profile == null
						// You can check if the user is new or existing:
						// result.additionalUserInfo.isNewUser

						if (customId) {
							await mergeRecords({ customId })
						}
						setStage('verified')
						setUserInfo({ isNew: result.additionalUserInfo?.isNewUser, email: result.user?.email })
						// https://help.mixpanel.com/hc/en-us/articles/115004497803-Identity-Management-Best-Practices
						if (result.additionalUserInfo?.isNewUser) {
							mixpanel.alias(result.user?.uid as string)
						}
						mixpanel.track(AppStateEvent.authSignInCompleteWithEmailLink)
					})
					.catch((error) => {
						// console.log(`signInWithEmailLink ${JSON.stringify(error)}`)
						setStage('verification-error')
						setVerificationError(error.message)
						// Some error occurred, you can inspect the code: error.code
						// Common errors could be invalid email and invalid or expired OTPs.
					})
			}
		} else {
			setStage('verification-error')
			setVerificationError('Please try clicking the verification link that was sent to you via email.')
		}
	}, [email, customId, stage, mixpanel])

	const verified = (
		<>
			{/* {`${userInfo && (userInfo?.isNew ? 'Welcome ' : 'Welcome back')}, ${userInfo && userInfo?.email}🎉`} */}
			<Styles.Title>
				{`${userInfo && (userInfo?.isNew ? 'Welcome' : 'Welcome Back')} 🎉`}
			</Styles.Title>
			{
				customId && (
					<Styles.SubTitle>
						You will be notified when your Stickie is viewed!
					</Styles.SubTitle>
				)
			}
		</>
	)

	const provideEmail = (
		<>
			<Styles.Title>One Last Step</Styles.Title>
			<Styles.SubTitle>Please re-enter your email address to confirm you identifity</Styles.SubTitle>
			<Styles.SignUpForm>
				<EmailComponent
				// initialEmail={typeof window !== 'undefined' ? window.localStorage.getItem('emailForSignIn') || '' : ''}
					onSubmit={(data:IEmail) => {
						setEmail(data.email)
						setStage('verifying')
					}}
					defaultValue=""
					buttonText="Verify"
				/>
			</Styles.SignUpForm>
		</>
	)

	const verifying = (
		<Styles.SubTitle>
			verifying...
		</Styles.SubTitle>
	)

	const verificationErrorUI = (
		<>
			<Styles.Title>
				Ooooops...
			</Styles.Title>
			<Styles.SubTitle>
				{verificationError}
			</Styles.SubTitle>
			<Styles.Navigation>

				<Styles.StyledLink2 to="/account/signup">Resend Link</Styles.StyledLink2>
			</Styles.Navigation>
		</>
	)

	return (
		<InsidePageLayout>
			<Styles.InnerBody>
				{
					stage === 'verifying' && (
						verifying
					)
				}

				{
					stage === 'verified' && (
						<>
							{verified}
							<DiscoverButton />
						</>
					)
				}

				{
					stage === 'email-missing' && (
						provideEmail
					)
				}

				{
					stage === 'verification-error' && (
						verificationErrorUI
					)
				}

			</Styles.InnerBody>
		</InsidePageLayout>

	)
}

export default withLocation(FinishSignUp)
